import React from "react"
import ReactDOM from "react-dom"

import { AnimatePresence, motion } from "framer-motion"
import Portal from "./portal"
import ContactForm from "./contact-form"

const ContactDialog = ({ isOpen, onClose }) => {
  const modalVariant = {
    initial: { opacity: 0 },
    isOpen: { opacity: 1 },
    exit: { opacity: 0 }
  }
  const containerVariant = {
    //initial: { top: "-50%", transition: { type: "spring" } },
    //isOpen: { top: "50%" },
    //exit: { top: "-50%" }
    initial: { scale: 0, transition: { type: "spring" } },
    isOpen: { scale: 1 },
    exit: { scale: 0 }
  }

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="fixed top-0 left-0 w-screen h-screen bg-black z-40 bg-opacity-50 pointer-events-none flex items-center justify-center"
            initial={"initial"}
            animate={"isOpen"}
            exit={"exit"}
            variants={modalVariant}
          >
            <motion.div
              className="p-2 w-full max-w-xl z-50"
              variants={containerVariant}
            >
              <div className="py-4 w-full border-gray-500 bg-white shadow rounded pointer-events-auto">
                <h1 className="text-center font-semibold text-3xl mb-8 pb-4 border-b border-gray-200">Contact us</h1>
                <ContactForm onCancel={onClose} />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  )
}

export default ContactDialog