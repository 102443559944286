import React from "react"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"


const X_DIRECTION = {
  "up": 0,
  "left": "-100%",
  "right": "100%",
}

const Y_DIRECTION = {
  "up": 200,
  "left": 0,
  "right": 0,
}

const ScrollIntoView = ({ direction, children }) => {
  const controls = useAnimation()
  const {ref, inView} = useInView()

  React.useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  const x = X_DIRECTION[direction]
  const y = Y_DIRECTION[direction]
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{
        duration: 1
      }}
      variants={{
        visible: { opacity: 1.0, y: 0, x: 0 },
        hidden: { opacity: 0.1, y, x },
      }}
    >
      {children}
    </motion.div>
  )
}

export default ScrollIntoView