import React from "react"

const ContactForm = ({onCancel}) => {
  const FormField = ({ label, children }) => (
    <div className="mb-6">
      <label className="mb-2">{label}</label>
      <div>{children}</div>
    </div>
  )

  return (
    <form method="post" action="https://www.flexyform.com/f/d9778309921a11f5fe3ceee12f69d9a9b80d880f">
      <div className="px-8">
        <FormField label="Full Name:">
          <input
            type="text"
            name="full_name"
            className="w-full"
          />
        </FormField>

        <FormField label="Email Address:">
          <input
            type="email"
            name="_reply_to"
            className="w-full"
          />
        </FormField>
        <FormField label="How can we help?">
            <textarea
              name="message"
              className="w-full"
              rows={5}></textarea>
        </FormField>
      </div>
      <div className="flex flex-row justify-end border-t border-gray-300 px-8 pt-4">
        <input type="hidden" name="_recaptcha" id="_recaptcha"/>
        <script src="https://www.google.com/recaptcha/api.js?render=6Lf7UsoUAAAAACT2Z6gLyh7RTDfyYGxfZ-M4D0ph"></script>
        <script src="https://www.flexyform.com/js/recaptcha.js"></script>

        <button
          onClick={() => onCancel()}
          type="button"
          className="px-8 py-4 rounded mx-4 border border-gray-500 hover:border-cornflower-blue-800 hover:text-cornflower-blue-800"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-8 py-4 rounded text-white bg-cornflower-blue-800 hover:bg-cornflower-blue-700 w-full md:w-auto"
        >
          Send
        </button>
      </div>
    </form>
  )
}

export default ContactForm