import React from "react"
import HeaderContent from "./header-content"


const HeaderFixed = (_props) => {
  const [scroll, setScroll] = React.useState(0)
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    const onScroll = (evt) => {
      let target = evt.target
      setScroll(target.documentElement.scrollTop)
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [scroll])

  const headerContainerClasses =
    (scroll > 10)
	? "bg-white text-black border-b border-gray-700"
	: (open ? "bg-white border-b border-gray-700 md:border-b-0 md:bg-white text-black md:text-black" : "text-black bg-white md:bg-opacity-75")

  return (
    <div
      className={`${headerContainerClasses} z-30 fixed w-full p-0`}
    >
      <header className="relative">
        <HeaderContent onMobileMenuClick={visible => setOpen(visible)}/>
      </header>
    </div>
  )
}

HeaderFixed.propTypes = {}

HeaderFixed.defaultProps = {}

export default HeaderFixed
