import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"

import HeaderFixed from "../components/header-fixed"
import { LogoEthereum, LogoHyperledger, LogoStellar, LogoSubstrate, LogoPolkadot } from "../components/crypto-logo"

import SEO from "../components/seo"
import Footer from "../components/footer"
import ScrollIntoView from "../components/scroll-into-view"
import ContactDialog from "../components/contact"
import { LeftSlope, RightSlope } from "../components/slopes"

const SectionTitle = ({ title }) => (
  <div className="text-center mb-16">
    <h2 className="text-4xl text-indigo-800">{title}</h2>
  </div>
)

const Hero = () => {
    return (
	<div className="pb-16 md:pb-0 md:h-screen bg-red">
	    <div className="flex flex-col md:h-full pt-28 md:pt-0 max-w-7xl mx-auto items-center justify-center">
		<h1 className="z-10 px-16 text-6xl bg-white bg-opacity-75">Boost the <span className="font-bold">Digital Assets</span> economy</h1>
		<h2 className="z-10 px-16 mt-8 md:mt-24 text-3xl bg-white bg-opacity-75">
		<span className="text-indigo-600 md:text-black">Exploring</span> how to build dApps faster, <span className="text-indigo-600 md:text-black">finding</span> new digital assets use cases, <span className="text-indigo-600 md:text-black">supporting</span> communities</h2>
	    </div>
	    <div className="absolute top-0 right-0 hidden md:block">
		<div style={{fontSize: "560px", lineHeight: "400px"}} className="z-0 pr-24 pt-20 font-bold text-indigo-600">A</div>
		<div style={{fontSize: "288px"}} className="z-0 pl-36 text-indigo-600">D</div>
	    </div>
	    <div className="absolute bottom-0 left-0 hidden md:block">
		<div style={{fontSize: "450px", lineHeight: "300px"}} className="z-0 text-indigo-600">B</div>
		<div style={{fontSize: "288px", lineHeight: "150px"}} className="z-0 pb-8 pl-80 font-bold text-indigo-600">E</div>
		</div>
	</div>
    )
}

const PhilosophySection = () => {
    return (
      <section className="w-full relative">
          <div className="max-w-7xl px-16 mx-auto py-24 relative z-10 grid grid-flow-row grid-cols-2 md:grid-cols-3 text-2xl text-white">
	      <div className="col-span-2">
		  <span className="dropcaps">T</span>ake the right decisions. Having a clear picture of a project’s challenges early increases the probability of on-time delivery.
	      </div>
	      <div className="md:col-start-2 col-span-2 mt-8">
		  <span className="dropcaps">H</span>andle blockchain challenges. Developing blockchain projects requires handling delayed transactions and more: use the right tools to reduce the impact on applications.
	      </div>
	      <div className="col-span-2 mt-8">
		  <span className="dropcaps">R</span>elease new features faster. State-of-the-art development and testing techniques increase developer velocity and reduce the number of bugs in production.
	      </div>
          </div>
	  <div className="absolute top-0 left-0 w-full h-full">
	      <div className="h-1/5 w-full bg-indigo-400"></div>
	      <div className="h-1/5 w-full bg-indigo-500"></div>
	      <div className="h-1/5 w-full bg-indigo-600"></div>
	      <div className="h-1/5 w-full bg-indigo-700"></div>
	      <div className="h-1/5 w-full bg-indigo-800"></div>
	  </div>
      </section>
    )
}

const OurApproachSection = ({ approachBgUrl }) => {
  return (
      <section style={{backgroundImage: `url('${approachBgUrl}')`}} className="py-24 bg-center bg-no-repeat bg-cover bg-fixed">
        <div className="max-w-7xl mx-auto px-16 py-12 bg-white border border-indigo-600 rounded">
            <SectionTitle title="Our Approach" />
	    <ul className="text-2xl">
		<li className="circle list-none mb-4">Decide which network or layer-2 solution is the best for the use case at hand. We understand the trade-offs between different solutions.</li>
		<li className="circle list-none mb-4">Use an event-driven architecture to build fault-tolerant applications. We build applications that automatically recover when transactions fail.</li>
		<li className="circle list-none">Catch bugs before they are deployed to production by using property-based testing and static analysis of the application's smart contracts.</li>
	    </ul>
        </div>
      </section>
  )
}

const ContactUsSection = () => {
  const [isOpen, setOpen] = React.useState(false)
  return (
    <ScrollIntoView direction="up">
      <section className="container mx-auto px-8 md:px-0 py-12 text-center">
        <button
          className="bg-cornflower-blue-800 hover:bg-cornflower-blue-700 rounded-full px-16 py-6 text-white"
          onClick={() => setOpen(true)}
        >
          Contact us
        </button>
        <ContactDialog isOpen={isOpen} onClose={() => setOpen(false)} />
      </section>
    </ScrollIntoView>
  )
}

const IndexPage = () => {
    const { approachBg } = useStaticQuery(
	graphql`
          query {
            approachBg: file(relativePath: { eq: "ph-bg.png" }) {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        `
    )

    return (
	<div className="w-full bg-white">
	    <SEO title="Boost the Digital Assets economy" />
	    <HeaderFixed />
	    <main>
		<Hero />
		<PhilosophySection />
		<OurApproachSection approachBgUrl={approachBg.childImageSharp.original.src} />
		<ContactUsSection />
	    </main>
	    <Footer />
	</div>
    )
}

export default IndexPage
